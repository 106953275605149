const radius = {
 none: "0px",
 xxs: "2px",
 xs: "4px",
 sm: "6px",
 md: "8px",
 lg: "10px",
 xl: "12px",
 xxl: "16px",
 xxxl: "20px",
 xxxxl: "24px",
 full: "9999px"
} as const;
export type Radius = typeof radius[keyof typeof radius];
export default radius
