import colors from "@/utils/colors";

export interface SizeProps {
    width: string;
    height: string;
    overflow?: string;
}

export interface UIModalProps {
    open: boolean;
    onClose: () => void;
    logo?: string;
    titleContent?: React.ReactNode;
    body?: React.ReactNode;
    footer?: React.ReactNode;
    size?: SizeProps;
    headerStyles?: React.CSSProperties;
    bodyStyles?: React.CSSProperties;
    footerStyles?: React.CSSProperties;
    noClose?: boolean;
}

interface PaperProps {
    size: {
        width: string;
        height: string;
    };
}

export type ModalType = 'ok' | 'error';
export interface ModalInfo {
    open: boolean;
    type: ModalType;
}

export const textStyle = {
    title: { color: colors.blue1, fontSize: '30px' },
    message: { color: colors.gray600, fontSize: '16px', fontWeight: 300},
    modalTitle: { fontSize: '20px', color: colors.blue1 , fontWeight: 700, margin: 0},
    modalMessage: { fontSize: '16px', color: colors.gray600,  margin: 0 }
};
